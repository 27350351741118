<template>
    <div>
        <apexchart type="pie" height="300" :options="chartOptions" :series="values"></apexchart>
    </div>
</template>

<script>

export default {
    name: "PieChartSubscription",
    props: {
        data: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.data.list.forEach((item) => {
            this.chartOptions.labels.push(item.title);
            this.values.push(item.payments_count);
        });

        this.chartOptions.labels = this.labels;
    },
    data() {
        return {
            values: [],
            labels: [],
            chartOptions: {
                labels: [],
                colors: ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51", "#d62828"],
                legend: {
                    position: "top",
                    horizontalAlign: "center",
                },
                title: {
                    text: this.title,
                    align: "center",
                    style: {
                        fontSize: "18px",
                        fontWeight: "bold",
                    },
                },
            },
        };
    },
};
</script>