<template>
  <div>
    <v-file-input @change="uploadFile" :label="label" truncate-length="125" />
    <v-alert v-if="bufferValue > 0">
      {{ bufferValue }} %
    </v-alert>
    <v-progress-linear v-if="bufferValue > 0" :value="bufferValue"></v-progress-linear>
    <div :id="'loadingAws_' + this.$props.fieldName" style="text-align: center; display: none; margin-top: 20px">
      <v-progress-circular indeterminate color="green"></v-progress-circular>
      <p> Sincronizando com o Bunny Streaming...</p>
    </div>
  </div>
</template>

<script>
import { DirectUpload } from '@rails/activestorage';
import S3 from "aws-s3";
import axios from "axios";

export default {
  computed: {
    loadPercentret() {
      return this.loadPercent
    },
    s3Keys() {
      return this.$auth.user.s3_keys;
    },
    config() {
      return {
        bucketName: "weflowapp",
        region: "sfo3",
        acl: 'public-read',
        accessKeyId: "V55IHDRFGGENUCW5UXPO",
        secretAccessKey: "kMf6S25sDXYnQQ4VQiXTBiLTZqv2GfY83U",
        s3Url: "https://weflowapp.sfo3.digitaloceanspaces.com",
      };
    },
    configAws() {
      return {
        bucketName: "weflowapp",
        region: "sa-east-1",
        accessKeyId: "AKIA2V5XPX423BLHSS64",
        secretAccessKey: "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",
      };
    },
    baseURL() {
      return "https://vue-screencasts-uploads.s3-us-west-2.amazonaws.com";
    },
    S3Client() {
      return new S3(this.config);
    },
    S3ClientAmazon() {
      return new S3(this.configAws);
    },
    newFileName() {
      return Math.random().toString().slice(2);
    },
    url() {
      return `${this.baseURL}/${this.directory}/${this.newFileName}`;
    },
  },
  methods: {
    changeAwsLoader() {
      this.awsSync = !this.awsSync;
    },
    onProgress(event) {
      console.log(parseInt(event.loaded / this.fileSize * 100))
      this.bufferValue = parseInt(event.loaded / this.fileSize * 100)
    },
    directUploadWillStoreFileWithXHR(xhr) {
      xhr.upload.addEventListener("progress", event => this.onProgress(event))
    },
    uploadFile(file) {
      this.fileSize = file.size;

      const uploadType = this.$props.fieldName;

      const upload = new DirectUpload(file, `${this.baseUrl}rails/active_storage/direct_uploads`, {
        directUploadWillStoreFileWithXHR: (xhr) => {
          console.log('Direct upload xhr: ', xhr);
          // TODO: REMOVE NEXT LINE WHEN MIRROR IS OVER 
          xhr.setRequestHeader('x-amz-acl', 'public-read');
          xhr.upload.addEventListener("progress", event => this.onProgress(event))
        }
      })

      upload.create(async (error, blob) => {
        console.log('blob before error: ', blob);

        if (error) {
          // Handle the error
          console.log('blob: ', blob);
          console.log('error: ', error);
        } else {

          const formData = new FormData();
          formData.append('file', file);
          formData.append('type', uploadType);

          document.getElementById('loadingAws_' + uploadType).style.display = 'block';

          this.uploadToBunny(file, file.name, uploadType);

          this.$api.put(`${this.baseUrl}api/v1/class_items/${this.$route.params.id}`, {
            class_item: { [this.$props.fieldName]: blob.signed_id }
          }).then((resp) => {
            // this.awsSync = false;
            console.log(resp)
          }).catch((err) => {
            console.log("erro", err)
          })
          // console.log(blob)
        }
      })
    },
    async uploadToBunny(file, title, type) {
      try {
        // Cria objeto do video na Bunny
        const createVideoResponse = await this.$api.post(
          `https://video.bunnycdn.com/library/${this.libraryId}/videos`,
          {
            title: title + '_' + type,
            collection_id: null,
            enabled_resolutions: [240, 360, 480, 720, 1080],
          },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              AccessKey: this.bunnyKey,
            },
          }
        );

        if (createVideoResponse.status !== 200) {
          alert("Erro ao criar vídeo na Bunny.");
          return;
        }

        const videoId = createVideoResponse.data.guid;
        const keyType = type == 'video' ? 'bunny_video_id' : 'bunny_graphic_id';

        // Atualiza o class_item com o id do video na Bunny
        this.$api.put(`${this.baseUrl}api/v1/class_items/${this.$route.params.id}`, {
          class_item: { [keyType]: videoId }
        })

        // Upload diretamente para o Bunny, não passa mais pelo backend
        const uploadResponse = await axios.put(`https://video.bunnycdn.com/library/${this.libraryId}/videos/${videoId}?skipEncoding=false`, file, {
          headers: {
            AccessKey: this.bunnyKey,
            'Content-Type': 'video/mp4'
          }
        });

        if (uploadResponse.status === 200) {
          alert("Upload para o Bunny realizado com sucesso!");
          document.getElementById('loadingAws_' + type).style.display = 'none';
        } else {
          alert("Erro ao enviar o conteúdo do vídeo para o Bunny Stream.");
          document.getElementById('loadingAws_' + type).style.display = 'none';
        }
      } catch (error) {
        console.error("Erro:", error);
        alert("Ocorreu um erro durante o upload do vídeo.");
      }
    },
  },
  data: () => ({
    // baseUrl: 'http://localhost:3000/',
    baseUrl: 'https://api.weflowoficial.com/',
    // baseUrl: 'https://sandbox.api.weflowoficial.com/',
    isLoading: false,
    loadPercent: 0,
    bufferValue: 0,
    fileSize: 0,
    awsSync: false,
    label: "Adicionar Vídeo",
    newUri: '',
    libraryId: '354062',
    bunnyKey: '40765a8e-137c-40f8-87b66e28f4b9-93a6-4fb1'
  }),
  props: ["fieldName", "obj", "directory"],
};
</script>
