<template>
    <SubscriptionReport />
  </template>
  
  <script>
  import SubscriptionReport from "@/components/Reports/SubscriptionReport.vue";
  export default {
    components: {
        SubscriptionReport,
    },
  };
  </script>
  