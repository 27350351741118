<template>
  <v-container>
    <v-card :loading="loading" class="mx-auto my-12" max-width="374">
      <v-card-title> Cadastrar Categoria</v-card-title>
      <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
      </template>
      <v-form @submit.prevent="submit" ref="form">
        <v-card-text>
          <v-alert type="success" v-if="successMsg">
            Parabéns sua mensagem foi enviada com sucesso!
          </v-alert>
          <v-row align="center" class="mx-0">
            <v-text-field v-model="form.title" label="Título" :rules="[(v) => !!v || 'Título é obrigatório']"
              hide-details="auto"></v-text-field><br />

            <v-text-field v-model="form.order_number" label="Número de ordernação" type="number"
              :rules="[(v) => !!v || 'Número de ordernação é obrigatório']" hide-details="auto"></v-text-field>

            <v-text-field v-model="form.description" label="Descrição"
              :rules="[(v) => !!v || 'Descrição é obrigatório']" hide-details="auto"></v-text-field>

            <v-text-field v-model="form.graphic_name" label="Nome do gráfico"
              :rules="[(v) => !!v || 'Nome do gráfico é obrigatório']" hide-details="auto"></v-text-field>

            <v-checkbox style="width: 100%; margin-top: 10px;" v-model="form.has_subcategory"
              :label="`Permite subcategoria?`" />
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn block type="submit" color="primary">
            <span v-if="loading"> Carregando </span>
            <span v-else> Enviar </span>
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>


<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("User", ["LOGIN_USER"]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let url =
          "/class_categories";

        const resp = await this.$api.post(url, {
          class_category: { ...this.form },
        });
        if (resp.status === 200) {
          this.loading = false;
          this.successMsg = true;
        } else {
          this.loading = false;
        }
      }
    },
  },
  data: () => ({
    formValid: true,
    loading: false,
    successMsg: false,
    validations: {},
    form: {
      title: "",
      order_number: 0,
      description: "",
      has_subcategory: false,
      graphic_name: "Gráfico"
    },
  }),
};
</script>

<style scoped>
.logo-form {
  width: 100px;
  margin-left: 35%;
  margin-top: 20px;
  height: 100px;
}
</style>