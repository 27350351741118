import Vue from 'vue'
import './plugins/axios'
import store from './stores'
import './plugins/mask';
import router from './router';
import App from './App.vue'
import VuePapaParse from 'vue-papa-parse';
import vuetify from './plugins/vuetify';
import firebase from 'firebase';
import VueApexCharts from 'vue-apexcharts';

Vue.config.productionTip = false

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(VuePapaParse);


const firebaseConfig = {
  apiKey: "AIzaSyAEN89O35SvDAcRRPJq-BOmwkjdHaCD13E",
  authDomain: "weflow-c9fbb.firebaseapp.com",
  databaseURL: "https://weflow-c9fbb-default-rtdb.firebaseio.com",
  projectId: "weflow-c9fbb",
  storageBucket: "weflow-c9fbb.appspot.com",
  messagingSenderId: "552996759689",
  appId: "1:552996759689:web:686660889dd4aa004d3e58",
  measurementId: "G-VHLK6EH1WC"
};
firebase.initializeApp(firebaseConfig);
const db = firebase.database();

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export {db}